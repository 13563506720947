<template>
  <div v-if="dataLoaded" class="place-popup">
    <h3>{{ title }}</h3>
    <p v-html="description"></p>
    <div id="thumbnails-container" v-if="thumbnails.length > 0">
      <img
        v-for="(thumbnail, index) in thumbnails"
        v-bind:key="index"
        v-bind:src="thumbnail"
        v-on:click="showPicture(index)"
      />
    </div>
    <div>
      <v-chip v-for="tag in tags" small :key="tag.id" @click="tagSelected(tag)">{{ tag.title }}</v-chip>
    </div>
    <v-icon color="#261401" large v-on:click="gotoFacebook">mdi-facebook</v-icon>
    <v-icon color="#261401" large v-on:click="gotoTwitter">mdi-twitter</v-icon>
  </div>
  <div v-else class="place-spinner">
    <div></div>
  </div>
</template>

<script>
import DataRequest from '../../libs/DataRequest';

export default {
  name: 'PlacePopup',
  props: {
    placeId: null,
    markerClicked: null,
  },
  data() {
    return {
      dataLoaded: false,
      title: '',
      description: '',
      pictures: [],
      thumbnails: [],
      tags: [],
    };
  },
  mounted() {
    if (this.dataInCache()) {
      this.preparePlaceInformations();
    }
  },
  watch: {
    markerClicked() {
      if (!this.dataInCache()) {
        DataRequest.get(`/place/informations/${this.placeId}`, (placeData) => {
          localStorage[`placeData${this.placeId}`] = JSON.stringify(placeData);
          this.preparePlaceInformations();
        });
      }
    },
  },
  methods: {
    preparePlaceInformations() {
      const placeData = JSON.parse(localStorage[`placeData${this.placeId}`]);
      this.title = placeData.title;
      this.description = placeData.description;
      this.tags = placeData.tags;
      this.dataLoaded = true;
      this.pictures = placeData.pictures;
      this.thumbnails = placeData.pictures_thumb;
    },
    clicked() {
      if (!this.dataInCache()) {
        DataRequest.get(`/place/informations/${this.placeId}`, (placeData) => {
          localStorage[`placeData${this.placeId}`] = JSON.stringify(placeData);
          this.preparePlaceInformations();
        });
      }
    },
    tagSelected(tag) {
      this.$store.commit('addTag', tag);
      this.$eventBus.$emit('tags-updated');
      this.$eventBus.$emit('showTagMapDialog');
    },
    dataInCache() {
      return localStorage[`placeData${this.placeId}`] !== undefined;
    },
    showPicture(index) {
      this.$eventBus.$emit('show-picture', this.title, this.pictures[index]);
    },
    gotoFacebook() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=https://www.c-est-la.fr/api/share/${
          this.placeId}`,
        '_blank',
      );
    },
    gotoTwitter() {
      window.open(
        `https://twitter.com/share?text=${
          this.title
        } - ${
          this.description
        }&url=https://www.c-est-la.fr/api/share/${
          this.placeId}`,
        '_blank',
      );
    },
  },
};
</script>

<style>
.place-popup p {
  font-size: 1.1rem;
}

.leaflet-popup {
  min-width: 20rem;
}

.place-spinner {
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  width: 3rem;
}

.place-spinner > div {
  background-color: #444;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  animation: place-spinner 2.4s ease infinite;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.75);
}

.place-popup img {
  cursor: pointer;
}

.place-popup button {
  margin-right: 0.5rem;
}

.place-popup .v-chip {
  margin: 0.3rem 0.3rem 0.3rem 0;
}

@keyframes place-spinner {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(720deg);
  }
}

#thumbnails-container {
  text-align: center;
}

#thumbnails-container img {
  margin: 0.2rem;
}
</style>
