<template>
  <v-fab-transition>
    <v-btn
      v-show="showed"
      class="tag-dialog-button"
      color="#261401"
      fab
      top
      absolute
      right
      dark
      v-on:click="showTagMapDialog"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'ButtonTagMapDialog',
  data: () => ({
    showed: true,
  }),
  created() {
    this.$eventBus.$on('showTagMapDialog', () => {
      this.showed = false;
    });
    this.$eventBus.$on('hideTagMapDialog', () => {
      this.showed = true;
    });
  },
  destroyed() {
    this.$eventBus.$off('showTagMapDialog');
    this.$eventBus.$off('hideTagMapDialog');
  },
  methods: {
    showTagMapDialog() {
      this.$eventBus.$emit('showTagMapDialog');
    },
  },
};
</script>

<style scoped>
.tag-dialog-button {
  z-index: 9000;
  top: 1rem !important;
}
</style>
