<template>
  <div class="tags-list">
    <span v-for="viewportSuggest in viewportSuggests" v-bind:key="viewportSuggest.id">
      <v-btn
        class="best-suggest"
        v-bind:style="'font-size: ' +  viewportSuggest.size + 'rem; height: ' + (viewportSuggest.size + 0.6) + 'rem'"
        v-on:click="selectedBestSuggests(viewportSuggest.id, viewportSuggest.title)"
        depressed
      >{{ viewportSuggest.title }}</v-btn>
    </span>
  </div>
</template>

<script>
import Shared from '../../../libs/Shared';
import DataRequest from '../../../libs/DataRequest';

export default {
  name: 'CloudTagsList',
  props: {
    bounds: null,
  },
  data() {
    return {
      viewportSuggests: [],
      tagResultsCount: 10,
    };
  },
  created() {
    this.$eventBus.$on('tags-updated', this.searchTagsChanged);
  },
  destroyed() {
    this.$eventBus.$off('tags-updated', this.searchTagsChanged);
  },
  mounted() {
    if (this.bounds !== null) {
      this.updateBestSuggests();
    }
  },
  watch: {
    bounds() {
      // Mise à jour des suggestions de la fenêtre
      this.updateBestSuggests();
    },
  },
  methods: {
    searchTagsChanged() {
      this.updateBestSuggests();
    },
    updateBestSuggests() {
      const urlViewport = Shared.boundsToUrl(this.bounds);
      const urlTags = Shared.tagsToUrl(this.$store.getters.tags);
      DataRequest.get(
        `/tag/suggestsviewport/${
          urlTags
        }/${
          urlViewport
        }/${
          this.tagResultsCount}`,
        (viewportSuggests) => {
          this.viewportSuggests = [];
          // y = ax + b
          // a = (y2 - y1) / (x2 - x1)
          // b = y - ax
          // Taille de la police
          const maxSize = 1.5;
          const minSize = 0.8;
          if (viewportSuggests.length > 1) {
            const diff = viewportSuggests[0].total
              - viewportSuggests[viewportSuggests.length - 1].total;
            if (diff > 0) {
              const determinant = (maxSize - minSize) / diff;
              const step = maxSize - viewportSuggests[0].total * determinant;
              for (let index = 0; index < viewportSuggests.length; ++index) {
                viewportSuggests[index].size = determinant * viewportSuggests[index].total + step;
              }
            } else {
              for (let index = 0; index < viewportSuggests.length; ++index) {
                viewportSuggests[index].size = maxSize;
              }
            }
          } else if (viewportSuggests.length > 0) {
            viewportSuggests[0].size = maxSize;
          }
          this.viewportSuggests = viewportSuggests;
        },
      );
    },
    selectedBestSuggests(id, title) {
      for (
        let viewportSuggestsIndex = 0;
        viewportSuggestsIndex < this.viewportSuggests.length;
        ++viewportSuggestsIndex
      ) {
        if (this.viewportSuggests[viewportSuggestsIndex].id === id) {
          this.viewportSuggests.splice(viewportSuggestsIndex, 1);
          break;
        }
      }
      this.$store.commit('addTag', { id, title });
      this.$eventBus.$emit('tag-added-from-cloud');
      this.$emit('selected');
    },
  },
};
</script>

<style scoped>
.tags-list {
  margin-left: 0.3rem;
}

.best-suggest {
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.2rem !important;
}
</style>
