<template>
  <v-dialog
    v-model="showed"
    v-bind:persistent="true"
    v-bind:hide-overlay="true"
    v-bind:max-width="width"
    v-bind:no-click-animation="true"
    v-bind:fullscreen="mobile"
  >
    <v-card class="tag-dialog-card">
      <v-btn icon color="grey" class="close-button" v-on:click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <ChipsSelectedTags v-bind:class="mobile ? 'mobile-close' : 'desktop-close'"></ChipsSelectedTags>
      <CloudTagsList v-bind:bounds="bounds" v-on:selected="onChange"></CloudTagsList>
      <div class="places-count">{{ $t('Showed places') }} : {{ placesCount }}</div>
    </v-card>
  </v-dialog>
</template>

<script>
import CloudTagsList from './CloudTagsList';
import ChipsSelectedTags from './ChipsSelectedTags';
import DataRequest from '../../../libs/DataRequest';

export default {
  name: 'TagMapDialog',
  components: { ChipsSelectedTags, CloudTagsList },
  data() {
    return {
      width: 0,
      mobile: false,
      showed: false,
    };
  },
  props: {
    bounds: null,
    placesCount: null,
    defaultTags: null,
  },
  created() {
    window.addEventListener('resize', this.resizeEvent);
    this.resizeEvent();
    this.$eventBus.$on('showTagMapDialog', () => {
      this.showed = true;
    });
    this.$eventBus.$on('hideTagMapDialog', () => {
      this.showed = false;
    });
  },
  mounted() {
    if (this.defaultTags !== null && this.defaultTags.length > 0) {
      this.readDefaultTags();
    }
  },
  destroyed() {
    // Supprime l'évènement (en cas de rechargement en phase de dev)
    window.removeEventListener('resize', this.resizeEvent);
    this.$eventBus.$off('showTagMapDialog');
    this.$eventBus.$off('hideTagMapDialog');
  },
  methods: {
    onChange() {
      this.$emit('tags-updated');
    },
    close() {
      this.$eventBus.$emit('hideTagMapDialog');
    },
    resizeEvent() {
      let newWidth = Math.round(window.innerWidth / 3);
      this.mobile = window.innerWidth < 500;
      // Taille de 1rem
      const maxWidth = 20 * parseInt(getComputedStyle(document.documentElement).fontSize);
      if (newWidth > maxWidth) {
        newWidth = maxWidth;
      }
      this.width = newWidth;
    },
    readDefaultTags() {
      const { suggests } = this.$store.getters;
      // Lecture des tags et recherche des données si l'utilisateur ne l'a pas en mémoire
      this.defaultTags.forEach((defaultTagId) => {
        if (suggests[defaultTagId] !== undefined) {
          this.addTag(suggests[defaultTagId]);
        } else {
          DataRequest.get(`/tag/${defaultTagId}`, this.addTag);
        }
      });
    },
    addTag(tagData) {
      this.$store.commit('addTag', tagData);
      this.$emit('tags-updated');
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  z-index: 9001 !important;
}
/** Force top right */
.v-dialog__content >>> .v-dialog {
  position: absolute !important;
  top: 2.5rem !important;
  right: 0 !important;
}

.tag-dialog-card {
  padding-top: 1rem;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.tag-dialog-search {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mobile-close {
  margin-top: 2rem;
}

.desktop-close {
  margin-top: 1rem;
}

.places-count {
  padding: 0.6rem 0.3rem;
}
</style>
