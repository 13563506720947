<template>
  <v-container fluid fill-height pa-0>
    <l-map
      v-bind:zoom="zoom"
      v-bind:center="userLocation"
      v-on:update:bounds="updateBounds"
      v-on:click="mapClicked"
    >
      <l-tile-layer
        v-bind:url="url"
        v-bind:attribution="attribution"
      ></l-tile-layer>
      <PlaceCluster v-bind:markers="markers"></PlaceCluster>
    </l-map>
    <TagMapDialog
      v-on:tags-updated="updateShowedPlaces"
      v-bind:bounds="viewBounds"
      v-bind:placesCount="markers.length"
      v-bind:defaultTags="defaultTags"
    ></TagMapDialog>
    <ButtonTagMapDialog></ButtonTagMapDialog>
    <PictureModal></PictureModal>
    <v-btn
      class="add-place-button"
      color="#261401"
      fab
      bottom
      absolute
      right
      dark
      v-on:click="toggleShareMode"
    >
      <v-icon v-if="!shareMode">mdi-plus</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </v-btn>
    <v-snackbar v-model="showShareMode">
      {{ $t('ClickToAdd') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showShareMode = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { latLng } from 'leaflet';
import { LMap, LTileLayer } from 'vue2-leaflet';
import DataRequest from '../libs/DataRequest';
import 'leaflet/dist/leaflet.css';
import PlaceCluster from '../components/Map/PlaceCluster.vue';
import TagMapDialog from '../components/Map/TagMapDialog/TagMapDialog.vue';
import ButtonTagMapDialog from '../components/Map/TagMapDialog/ButtonTagMapDialog.vue';
import Shared from '../libs/Shared';
import PictureModal from '../components/Map/PictureModal.vue';

export default {
  name: 'Map',
  components: {
    ButtonTagMapDialog,
    TagMapDialog,
    LMap,
    LTileLayer,
    PlaceCluster,
    PictureModal,
  },
  data: () => ({
    userLocation: latLng(0.0, 0.0),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    defaultTags: [],
    defaultPlaceData: null,
    viewBounds: null,
    zoom: 12,
    maxZoomLevel: 20,
    markers: [],
    pictureModal: false,
    pictureSrc: '',
    pictureTitle: '',
    shareMode: false,
    showShareMode: false,
  }),
  created() {
    this.$store.commit('setTags', []);
    if (this.$route.params.defaultTags !== undefined) {
      if (typeof this.$route.params.defaultTags === 'string') {
        this.defaultTags = this.$route.params.defaultTags.split(',');
      } else {
        this.defaultTags = [this.$route.params.defaultTags];
      }
    }
    if (this.$route.params.defaultPlace !== undefined) {
      this.defaultPlace = this.$route.params.defaultPlaceData;
      DataRequest.get(`/place/informations/${this.$route.params.defaultPlace}`, (placeData) => {
        this.userLocation = latLng(placeData.latitude, placeData.longitude);
      });
    }
    this.$eventBus.$on('tags-updated', this.updateShowedPlaces);
  },
  destroyed() {
    this.$eventBus.$off('tags-updated', this.updateShowedPlaces);
  },
  mounted() {
    this.loadDataFromLocalStorage();
  },
  methods: {
    mapClicked(clickData) {
      if (this.shareMode) {
        this.$router.push({ name: 'Contact', params: { defaultSubject: this.$t('ShareMsgTitle'), defaultContent: `${this.$t('ShareMsg')} ${clickData.latlng.lat}, ${clickData.latlng.lng}` } });
      }
    },
    loadDataFromLocalStorage() {
      if (this.defaultPlace !== null && this.defaultPlace === undefined) {
        if (localStorage.userLatitude !== 'undefined' && localStorage.userLatitude !== undefined) {
          this.userLocation = latLng(localStorage.userLatitude, localStorage.userLongitude);
        } else {
          this.getCountryUserLocation((userCoords) => {
            // Si le service a répondu sa position entre temps
            if (this.userLocation.lat === 0.0) {
              this.userLocation = latLng(userCoords.latitude, userCoords.longitude);
            }
            localStorage.setItem('userLatitude', userCoords.latitude);
            localStorage.setItem('userLongitude', userCoords.longitude);
          });
        }
        this.initUserLocation((userCoords) => {
          if (!this.isUserAlreadyMove()) {
            this.userLocation = latLng(userCoords.latitude, userCoords.longitude);
          }
          this.goToNearestPlaceIfNecessary();
        });
      }
      if (localStorage.zoom) {
        this.zoom = localStorage.zoom;
      }
    },
    isUserAlreadyMove() {
      return this.userLocation.lat !== 0.0 && this.userLocation.lng !== 0.0;
    },
    updateBounds(newBounds) {
      if (this.viewBounds === null || !this.viewBounds.contains(newBounds)) {
        this.viewBounds = newBounds;
        this.updateShowedPlaces();
      }
    },
    updateShowedPlaces() {
      if (this.viewBounds !== null) {
        const urlViewport = Shared.boundsToUrl(this.viewBounds);
        const urlTags = Shared.tagsToUrl(this.$store.getters.tags);
        DataRequest.get(`/place/getviewport/${urlTags}/${urlViewport}`, (places) => {
          this.markers = places;
        });
      }
    },
    initUserLocation(callbackFunc) {
      // Test si le navigateur supporte l'extension
      if (navigator.geolocation) {
        // Tente d'obtenir la localisation
        navigator.geolocation.getCurrentPosition(
          (position) => {
            callbackFunc(position.coords);
          },
          () => {
            callbackFunc({
              latitude: 48.86666667,
              longitude: 2.33333333,
            });
          },
          { maximumAge: 600000 },
        );
      }
    },
    getCountryUserLocation(callbackFunc) {
      DataRequest.get('/coords/default', (userCoords) => {
        callbackFunc(userCoords);
      });
    },
    toggleShareMode() {
      this.shareMode = !this.shareMode;
      this.showShareMode = this.shareMode;
    },
    goToNearestPlaceIfNecessary() {
      if (this.markers.length === 0) {
        DataRequest.get(`/place/nearest/${this.userLocation.lat}/${this.userLocation.lng}/${this.defaultTags.join(',')}`, (result) => {
          this.userLocation = latLng(result.latitude, result.longitude);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
html {
  overflow: auto;
}

/** Force over map */
.v-dialog__content {
  z-index: 9000 !important;
}

.add-place-button {
  z-index: 9000 !important;
  bottom: 1rem !important;
}

</style>
