<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title class="headline grey lighten-3">{{ title }}</v-card-title>
      <div>
        <v-img
          class="align-center"
          v-bind:src="src"
          v-bind:alt="title"
          v-bind:max-height="maxHeight"
          v-on:click="show = !show"
          contain
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PictureModal',
  data() {
    return {
      show: false,
      title: '',
      src: '',
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight * 0.8;
    },
    maxWidth() {
      return window.innerWidth * 0.8;
    },
  },
  created() {
    this.$eventBus.$on('show-picture', (title, src) => {
      this.title = title;
      this.src = src;
      this.show = true;
    });
  },
};
</script>

<style scoped>
.v-dialog__content {
  z-index: 9003 !important;
}
</style>
