<template>
  <v-combobox
    ref="tag-search"
    class="tag-dialog-search"
    v-model="selectedTags"
    v-bind:items="suggests"
    item-text="title"
    item-vallue="id"
    chips
    v-bind:label="$t('Filter places')"
    multiple
    return-object
    hide-selected
    v-bind:menu-props="{closeOnContentClick: true}"
    v-bind:search-input.sync="search"
    v-on:keyup="onSearch"
    v-on:input="tagChange"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind:key="data.item.id"
        v-bind:input-value="data.selected"
        v-on:click:close="data.parent.selectItem(data.item)"
        close
      >
        <strong v-html="getSmallTitle(data.item.title)"></strong>&nbsp;
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import DataRequest from '../../../libs/DataRequest';

export default {
  name: 'ChipsSelectedTags',
  data() {
    return {
      selectedTags: [],
      suggests: [],
      search: '',
      lastSearch: '',
    };
  },
  created() {
    this.$eventBus.$on('tag-added-from-cloud', this.tagAddedFromCloud);
    this.selectedTags = this.$store.getters.tags;
  },
  destroyed() {
    this.$eventBus.$off('tag-added-from-cloud', this.tagAddedFromCloud);
  },
  mounted() {
    this.prepareSuggests();
  },
  methods: {
    getSmallTitle(title) {
      if (title.length > 15) {
        return `${title.substr(0, 13)}&hellip;`;
      }
      return title;
    },
    tagAddedFromCloud() {
      this.selectedTags = this.$store.getters.tags;
    },
    prepareSuggests() {
      const storedSuggests = this.$store.getters.suggests;
      this.suggests = [];
      const tagIds = Object.keys(storedSuggests);
      for (const tagId of tagIds) {
        this.suggests.push(storedSuggests[tagId]);
      }
    },
    onSearch() {
      // Evite une recherche alors que les résultats sont déjà présents
      if (
        this.search !== null
        && this.search !== ''
        && (this.lastSearch === '' || this.search.indexOf(this.lastSearch) === -1)
      ) {
        DataRequest.get(`/tag/search/${this.search}`, (searchSuggests) => {
          this.lastSearch = this.search;
          this.$store.commit('addSuggests', searchSuggests);
          this.prepareSuggests();
        });
      }
    },
    tagChange() {
      this.search = '';
      this.$store.commit('setTags', this.selectedTags);
      this.$eventBus.$emit('tags-updated');
    },
  },
};
</script>

<style scoped>
</style>
