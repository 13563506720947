<template>
  <v-marker-cluster ref="cluster" v-bind:options="{showCoverageOnHover: false}">
    <PlaceMarker
      v-for="marker in markers"
      v-bind:key="marker.id"
      v-bind:placeId="marker.id"
      v-bind:latitude="marker.latitude"
      v-bind:longitude="marker.longitude"
    ></PlaceMarker>
  </v-marker-cluster>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import PlaceMarker from './PlaceMarker';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export default {
  name: 'PlaceCluster',
  props: {
    markers: null,
  },
  components: {
    PlaceMarker,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
};
</script>

<style scoped>
</style>
