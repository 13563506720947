<template>
  <l-marker v-bind:lat-lng="latLng" v-on:click="clicked">
    <l-popup v-bind:options="{maxWidth: 'auto'}">
      <PlacePopup v-bind:placeId="placeId" v-bind:markerClicked="markerClicked"></PlacePopup>
    </l-popup>
  </l-marker>
</template>

<script>
import { LMarker, LPopup } from 'vue2-leaflet';
import { latLng, Icon } from 'leaflet';

import PlacePopup from './PlacePopup';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'PlaceMarker',
  components: {
    PlacePopup,
    LMarker,
    LPopup,
  },
  props: {
    placeId: null,
    latitude: null,
    longitude: null,
  },
  data() {
    return {
      markerClicked: false,
    };
  },
  computed: {
    latLng() {
      return latLng(this.latitude, this.longitude);
    },
  },
  methods: {
    clicked() {
      this.markerClicked = true;
    },
  },
};
</script>
